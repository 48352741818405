/* NotoSans KR : https://fonts.google.com/earlyaccess#Noto+Sans+KR */
@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');

/* Global Styling */
// Footer
html {
  position: relative;
  min-height: 100%;
}

// Footer Height 60px + margin 20px
body {
  margin: 0 0 80px 0;
  background: rgb(239, 239, 239);
}

#root {
  overflow-x: hidden;
}
// Fix on material-ui style bug
span[type=button] {
  appearance: none;
}
a[type=button] {
  appearance: none !important;
}
button[type=button] {
  appearance: none !important;
}

// Prevent images from overflow
img {
  max-width: 100% !important;
  height: auto;
}

// Prevent iframes from overflow
iframe {
  max-width: 100% !important;
}

// Override default anchor style
a {
  text-decoration: none;
}

// Override default table style
table {
  max-width: 100% !important;
}

// Override default button style
button {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
}

// TODO: integrate content styling with editors
.artist-text-content > p {
  margin: 0;
  line-height: 1.7;
  font-size: 12pt;
}
