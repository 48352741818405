@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
/* NotoSans KR : https://fonts.google.com/earlyaccess#Noto+Sans+KR */
/* Global Styling */
html {
  position: relative;
  min-height: 100%; }

body {
  margin: 0 0 80px 0;
  background: #efefef; }

#root {
  overflow-x: hidden; }

span[type=button] {
  -webkit-appearance: none;
          appearance: none; }

a[type=button] {
  -webkit-appearance: none !important;
          appearance: none !important; }

button[type=button] {
  -webkit-appearance: none !important;
          appearance: none !important; }

img {
  max-width: 100% !important;
  height: auto; }

iframe {
  max-width: 100% !important; }

a {
  text-decoration: none; }

table {
  max-width: 100% !important; }

button {
  display: block;
  padding: 0;
  margin: 0;
  border: 0; }

.artist-text-content > p {
  margin: 0;
  line-height: 1.7;
  font-size: 12pt; }

